<template>
  <div>
    <div class="content">
      <div class="all SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="用户分类" class="searchItem">
            <el-select v-model="pageParams.userType" placeholder="请选择" size="mini" clearable filterable @change="searchList()">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用情况" class="searchItem">
            <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable filterable @change="searchList()">
              <el-option
                v-for="item in useCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" class="searchItem">
            <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable filterable @change="searchList()">
              <el-option
                v-for="item in enableStart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.tplName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="searchList()" size="mini" >查询</el-button>
             <el-button type="warning" @click="searchList(1)" size="mini" >重置</el-button>
          </el-form-item>
        </el-form>
    </div>
      <el-divider style="color: #f2f2f2"></el-divider>
      <!-- <div class="table-height"> -->
      <div class="tableTool">
        <span style="float:right;margin:0.5%;margin-right: 16px;">
          <el-button type="text" 
          style="border: 1px solid #409EFF;
    color: #409EFF;
    width: 80px;" size="mini" @click="goPath('/home/addSample')" v-if="$anthButtons.getButtonAuth('fmcjmbtj')">添加</el-button>
        </span>
      </div>
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            :stripe="true"
            height="calc(100vh - 460px)"
            :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}"
          >
        <el-table-column
          type="index"
          label="序号"
          width="60"
        >
        </el-table-column>
        <el-table-column
            prop="tplName"
            label="模板名称"
            show-overflow-tooltip
            width="500"
          >
        </el-table-column>
        <el-table-column
            prop="userTypeName"
            label="用户分类"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="itemsCount"
            label="采集项数量"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="used"
            label="使用情况"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column prop="generalStatus" label="启用状态">
          <template slot-scope="{row}">
              <el-tag v-if="row.generalStatus== 0" type="danger">禁用</el-tag>
              <el-tag v-if="row.generalStatus== 1" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="250">
          <template slot-scope="{row}">
            <div class="operateTool">
              <el-button type="primary" @click="goPath('/home/sampleDesc',row.id)" size="mini" v-if="$anthButtons.getButtonAuth('fmcjmbck')">查看</el-button>
              <el-button v-if="row.generalStatus== 0&&row.used=='未使用'&& $anthButtons.getButtonAuth('fmcjmbbj')" type="primary" plain  size="mini" @click="goPath('/home/addSample',row.id)">编辑</el-button>
              <el-button type="success" v-if="row.generalStatus== 0 && $anthButtons.getButtonAuth('fmcjmbqty')" @click="stateUpdata(row.id)" size="mini">启用</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  templatePageList,
  templateGeneralStatusToggle
} from "@/RequestPort/dict/cycle";
import {
  selectDictListByPid
} from "../../RequestPort/business/userFile.js";
import pageMixins from "@/utils/pageMixins";
export default {
  name:'Sample',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      tableData: [], //  表格数据
       total:0,
      title:'',
      dialogVisible:false,//启用弹框
      value:'',
      // 用户分类
      options:[],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ]
    };
  },
  mounted() {
    this.getList()
    selectDictListByPid({pid: 760}).then(res => {
      this.options = res.data
    })
  },
  methods: {
    /**
     * 获取列表
     */
    getList(){
      templatePageList(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 更改状态
     */
    stateUpdata(val){
      let that = this
      this.$confirm('是否启用此模板，启用后将自动停用该类型其他模板', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          templateGeneralStatusToggle({id:val}).then(res => {
            that.$message({
              type: 'success',
              message: res.data
            });
            that.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    /**
     * 搜索
     */
    searchList(val){
      if(val){
        this.pageParams ={
          current:1,
          size: 100,
        }
      } else {
        this.pageParams.current = 1
      }
      this.getList()
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    height: 25px;}
 .searchItem {
       margin-left: 1%;
    }
// .info-item {
//   margin-bottom: 20px;
// }
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.all {
  background-color:#fff;height:auto
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 294px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn{
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
.el-form-item{
  margin-bottom: 0;
  margin-left: 0;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
</style>